import Vue from 'vue';
import Vuex from 'vuex';
import keycloak from './modules/keycloak';
import category from './modules/category';
import ticket from './modules/ticket';
import autoLogin from './modules/autoLogin';
import survey from './modules/survey';
import manifestation from './modules/manifestation';
import identification from './modules/identification';
import toaster from './modules/toaster';

// Persist state
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    keycloak,
    category,
    ticket,
    autoLogin,
    survey,
    manifestation,
    identification,
    toaster
  },
  plugins: [
    createPersistedState({
      key: 'ouvidoria',
      storage: window.localStorage,
    })
  ],
});
