import api from '@/api/categories';

export default {
  state: {
    categories: null,
    categorySubjects: null,
    subject: null,
    subjects: null,
    searchData: null,
    subjectVotes: null,
    voteResponse: null,
    reports: null,
    homeData: null
  },

    getters: {
      getCategories: (state) =>  state.categories,
      categorySubjects: (state) =>  state.categorySubjects,
      selectedSubject: (state) => state.subject,
      getSearchData: (state) => state.searchData,
      subjectVotes: (state) => state.subjectVotes,
      getVoteResponse: (state) => state.voteResponse,
      subjects: (state) => state.subjects,
      reports: (state) => state.reports,
      homeData: (state) => state.homeData
    },

    actions: {
      async getAllCategories ({ commit } ){
        try{
          const response = await api.getCategories();

          commit('SET_CATEGORIES', response.data.data);

          return response.data.data;
        }
        catch (e){
          commit('SET_CATEGORIES', null);
        }
      },

      async getCategorySubjects({ commit } , id){
        try{
          const response = await api.getSingleCategorySubjects(id);

          commit('SET_CATEGORY_SUBJECTS', response.data.data);
        }
        catch (e){
          commit('SET_CATEGORY_SUBJECTS', null);
        }
      },

      async getSubjects({ commit }, filter) {
        try{
          const response = await api.getSubjectData(filter ?? '');

          commit('SET_SUBJECTS', response.data.data);
        }
        catch (e){
          commit('RESET_ALL_SUBJECTS');
        }
      },

      async getSingleSubject({ commit }, id) {
        try{
          const response = await api.getSubjectData(id);

          commit('SET_SELECTED_SUBJECT', response.data.data);
        }
        catch (e){
          commit('SET_SELECTED_SUBJECT', null);
        }
      },

      resetAllSubjects({ commit }) {
        commit('RESET_ALL_SUBJECTS');
      },

      setSelectedSubject({ commit } , subject ){
        commit('SET_SELECTED_SUBJECT', subject);
      },

      resetSelectedSubject({ commit }) {
        commit('RESET_SELECTED_SUBJECT');
      },

      async getSearch({ commit } ){
        try{
          const response = await api.getSearchData();

          commit('SET_SEARCH_DATA', response.data.data);

          return response.data;
        }
        catch (e){
          commit('SET_SEARCH_DATA', null);
        }
      },

      async getSubjectVotes({ commit } , subjectId){
        try{
          const response = await api.getVotes(subjectId);

          commit('SET_SUBJECT_VOTES', response.data.data);

          return response.data.data;
        }
        catch (e){
          commit('SET_SUBJECT_VOTES', null);
        }
      },

      async makeVote({ commit }, voteInfo) {
        try{
          const response = await api.postVote(voteInfo);

          commit('SET_VOTE_RESPONSE', response.data);

          return Promise.resolve(response.data.data);
        }
        catch (error){
          return Promise.reject(error.response);
        }
      },

      async getReports({ commit }) {
        try{
          const response = await api.fetchReports();

          commit('SET_REPORTS', response.data);
        }
        catch (error){
          return Promise.reject(error.response);
        }
      },

      async getHomeData({ commit }) {
        try {
          const response = await api.fetchHomeData();

          commit('SET_HOME_DATA', response.data.data);
        } catch (error) {
          return Promise.reject(error.response);
        }
      }
    },

  mutations: {
    SET_CATEGORIES(state, payload){
      state.categories = payload;
    },

    SET_CATEGORY_SUBJECTS(state, payload){
      state.categorySubjects = payload;
    },

    SET_SUBJECTS(state, payload) {
      state.subjects = payload;
    },

    RESET_ALL_SUBJECTS(state) {
      state.subjects = null;
    },

    SET_SELECTED_SUBJECT(state, payload){
      state.subject = payload;
    },

    RESET_SELECTED_SUBJECT(state){
      state.subject = null;
    },

    SET_SEARCH_DATA(state, payload){
      state.searchData = payload;
    },

    SET_SUBJECT_VOTES(state, payload){
      state.subjectVotes = payload;
    },

    SET_VOTE_RESPONSE(state, payload){
      state.voteResponse = payload;
    },

    SET_REPORTS(state, payload) {
      state.reports = payload;
    },

    SET_HOME_DATA(state, payload) {
      state.homeData = payload;
    }
  }
};
