export const currentRealm = {
  pmvv: false,
  pmci: false,
  pmcariacica: false,
  oabes: false,
  upcities: false,
};

const checkRealm = () => {
  const realm = process.env.VUE_APP_KEYCLOAK_REALM;

  switch (realm) {
    case 'pmvv':
      currentRealm.pmvv = true;
      break;
    case 'pmci':
      currentRealm.pmci = true;
      break;
    case 'pmcariacica':
      currentRealm.pmcariacica = true;
      break;
    case 'oabes':
      currentRealm.oabes = true;
      break;
    default:
      currentRealm.upcities = true;
      break;
  }
};

checkRealm();
