import api from '@/api/ticket';

export default {
  state: {
    makeTicketData: null,
    tickets: null,
    ticket: null,
    files: [],
    ticketResponse: null,
    cancelReasons: null,
    cancelResponse: null,
    answerResponse: null,
    evaluationResponse: null,
    selectedAddress: {
      map: {},
      otherMap: {}
    }
  },

  getters: {
    postTicketData: (state) => state.makeTicketData,
    getTickets: (state) => state.tickets,
    getTicket: (state) => state.ticket,
    getTicketResponse: (state) => state.ticketResponse,
    getCancelReasons: (state) => state.cancelReasons,
    getCancelResponse: (state) => state.cancelResponse,
    getAnswerResponse: (state) => state.answerResponse,
    getTicketFiles: (state) => state.files,
    getSelectedAddress: (state) => state.selectedAddress
  },

  actions: {
    async postTicket({ commit } , ticket ){
      try{

        var formData = new FormData();

        if(ticket.address?.street){
          let addressArray = Object.entries(ticket.address);

          addressArray.forEach(element => {
            formData.append(`address[${element[0]}]`, element[1]);
          });
        }

        formData.append('description', ticket.description);
        formData.append('origin', 'web');
        formData.append('subject_id', ticket.subject_id);
        formData.append('anonymous', ticket.anonymous);
        formData.append('is_confidential', ticket.confidential);

        if(ticket.previous_protocol)
          formData.append('previous_protocol', ticket.previous_protocol);

        if(ticket.manifestation)
          formData.append('manifestation', ticket.manifestation);

        if(ticket.department)
          formData.append('department_id', ticket.department.id);

        ticket.files.forEach(function (element, index) {
          formData.append(`file[${index}]`, element);
        });

        if(ticket.otherAddress?.street){
          formData.append(`subject_custom_field_answer[${ticket.otherAddressId}].address`,
            JSON.stringify(ticket.otherAddress));
        }

        if(ticket.choiceFieldAnswer) {
          formData.append(`subject_custom_field_answer[${ticket.choiceFieldId}]`, ticket.choiceFieldAnswer);
        }

        const response = await api.createTicket(formData);

        commit('SET_TICKET_RESPONSE', response.data.data);
        commit('RESET_TICKET_DATA');
        commit('RESET_TICKET_FILES');

        return Promise.resolve(response.data.data);

      }
      catch (error){
        return Promise.reject(error.response);
      }
    },

    resetTicket({ commit }) {
      commit('RESET_TICKET_DATA');
    },

    async getTicketsData({ commit }){
      try{
        const response = await api.getTickets();

        commit('SET_TICKETS', response.data.data);

        return Promise.resolve(response.data.data);
      }
      catch (error){
        commit('SET_TICKETS', null);

        return Promise.reject(error.response);
      }
    },

    async getTicketData({ commit } , ticketId ){

      try{
        const response = await api.getTicket(ticketId);

        commit('SET_TICKET', response.data.data);

        return Promise.resolve(response.data.data);

      }
      catch (error){
        commit('SET_TICKET', null);

        return Promise.reject(error.response);
      }
    },

    async getWhatsappTicket({ commit }, wppCode) {
      try {
        const response = await api.getWppTicket(wppCode);

        commit('SET_TICKET', response.data.data);

        return Promise.resolve(response.data.data);

      } catch (error) {
        commit('SET_TICKET', null);

        return Promise.reject(error.response);
      }
    },

    saveTicketFiles({ commit }, files) {
      commit('SET_TICKET_FILES', files[0]);
    },

    resetTicketFiles({ commit }) {
      commit('RESET_TICKET_FILES');
    },

    async getReasons({ commit }){
      try {
        const response = await api.getReason();

        commit('SET_REASONS', response.data.data);

        return Promise.resolve(response.data.data);

      }
      catch (error) {
        commit('SET_REASONS', null);

        return Promise.reject(error.response);
      }
    },

    async cancelRegisteredTicket({ commit }, cancelInfo){
      try{
        const response = await api.cancelTicket(cancelInfo.ticketId, cancelInfo.motive);

        commit('SET_CANCEL_RESPONSE', `O protocolo N° ${cancelInfo.ticketProtocol} foi cancelado.`);

        return Promise.resolve(response.data.data);

      }
      catch (error){
        commit('SET_CANCEL_RESPONSE', error.response.data);

        return Promise.reject(error.response);
      }
    },

    async addTicketAnswer({ commit }, answerInfo) {
      try {
        var formData = new FormData();

        if(answerInfo.answer.file.length > 0) {
          answerInfo.answer.file.forEach(function (element, index) {
            formData.append(`file[${index}]`, element);
          });
        }

        formData.append('response', answerInfo.answer.comment);

        const response = await api.addAnswer(answerInfo.ticketId, formData);

        commit('SET_ANSWER_RESPONSE', response.data.data);

        return Promise.resolve(response.data.data);

      }
      catch (error) {
        commit('SET_ANSWER_RESPONSE', 'Não foi possível adicionar um comentário ou arquivo.');

        return Promise.reject(error.response.data);
      }
    },

    async getAnonymousTicket({ commit }, protocolNumber) {
      try {
        const response = await api.anonymousTicket(protocolNumber);

        commit('SET_TICKET', response.data.data);

        return Promise.resolve(response.data.data);

      } catch (error) {
        commit('SET_TICKET', null);

        return Promise.reject(error.response);
      }
    },

    setTicket({ commit } , ticketData ){
      commit('SET_MAKE_TICKET_DATA', ticketData);
    },

    async evaluateTicket({ commit }, evaluationInfo) {
      try {
        const response = await api.evaluateTicket(evaluationInfo);

        commit('SET_EVALUATION_RESPONSE', null);

        return Promise.resolve(response.data);

      } catch (error) {
        commit('SET_EVALUATION_RESPONSE', null);

        return Promise.reject(error.response);
      }
    },

    saveSelectedAddress({ commit }, address) {
      commit('SET_USER_ADDRESS', address);
    }
  },
  mutations: {
    SET_TICKET_RESPONSE(state, payload){
      state.ticketResponse = payload;
    },
    SET_TICKETS(state, payload){
      state.tickets = payload;
    },

    SET_TICKET(state, payload){
      state.ticket = payload;
    },

    SET_TICKET_FILES(state, payload){
      state.files = payload;
    },

    RESET_TICKET_FILES(state) {
      state.files = [];
    },

    SET_MAKE_TICKET_DATA(state, payload){
      state.makeTicketData = payload;
    },

    RESET_TICKET_DATA(state){
      state.makeTicketData = null;
    },

    SET_REASONS(state, payload) {
      state.cancelReasons = payload;
    },

    SET_CANCEL_RESPONSE(state, payload) {
      state.cancelResponse = payload;
    },

    SET_ANSWER_RESPONSE(state, payload) {
      state.answerResponse = payload;
    },

    SET_EVALUATION_RESPONSE(state, payload) {
      state.evaluationResponse = payload;
    },

    SET_USER_ADDRESS(state, payload) {
      const addressId = Object.keys(payload)[0];

      state.selectedAddress[addressId] = payload[addressId];
    },

    RESET_USER_ADDRESS(state) {
      state.selectedAddress.map = {};
      state.selectedAddress.otherMap = {};
    },
  }
};
