import Keycloak from '@/api/keycloak.js';
import { setAuthHeader, resetAuthHeader }  from '@/config/axios/index'; // Axios

export default {
    state: {
      userInfo: {
        name: null,
        email: null,
        cpf: null,
        birthdate: null,
        phone: null,
        userId: null,
        imageUrl: null,
      },
      authenticated: false,
      userToken: null,
      refeshToken: null,
      keycloak: null,
      clientId: process.env.VUE_APP_KEYCLOAK_REDIRECT,
      unauthorizedError: false
    },

    getters: {
      userData: (state) => state.userInfo,
      userName: (state) =>  state.name,
      userEmail: (state) => state.email,
      userIsLogged: (state) => state.authenticated,
      userToken: (state) => state.userToken,
      userRefreshToken: (state) => state.userToken,
      clientId: (state) => state.clientId,
      verifyClientId: (state) => state.clientId === process.env.VUE_APP_KEYCLOAK_CLIENTID,
      showUnauthorizedError: (state) => state.unauthorizedError
    },

    actions: {
      async getUserInfo({ state, dispatch }) {
        try {
          const userInfo = await Keycloak.getUserInfo(state.keycloak.endpoints.userinfo());

          return userInfo.data;
        } catch (error) {
          await dispatch('initKeycloak');
        }
      },

      async initKeycloak({ commit, dispatch }, onload = '' ){
        try{
          const userInfo = await Keycloak.initKeycloak(onload);

          if(userInfo){
            commit('KEYCLOAK_INIT', userInfo);

            if(userInfo.authenticated) {
              commit('KEYCLOAK_LOGIN', userInfo);
            } else {
              dispatch('resetUserData');
            }
          }
        }
        catch (e){
          commit('KEYCLOAK_INIT',  { keycloak: null });
        }
      },

      async autologinKeycloak({ commit }, { token, refreshToken, clientId }) {
        try{
          const userInfo = await Keycloak.autologinKeycloak(token, refreshToken, clientId);

          commit('KEYCLOAK_INIT', userInfo);

          if(userInfo.authenticated){
            commit('KEYCLOAK_LOGIN', userInfo);
          }
        }
        catch (e){
          commit('KEYCLOAK_LOGIN', null);
        }
      },

      async loginKeycloak({ commit, state }, redirectUri){
        try{
          const userInfo = await state.keycloak.login(redirectUri);

          commit('KEYCLOAK_LOGIN', userInfo);
        }
        catch (e){
          commit('KEYCLOAK_USER_LOGOUT');
        }
      },

      async setClientId({ commit }, clientId) {
        commit('CLIENT_ID', clientId);
      },

      async registerKeycloak({ state }){
        await state.keycloak.register({
            redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECT,
        });
      },

      changeKeycloakOnload({ commit }, loginRequired){
        commit('KEYCLOAK_CHANGE_REQUIRED_LOGIN', loginRequired);
      },

      async userLogout({ commit, dispatch, state }){
        await dispatch('initKeycloak');

        Promise.allSettled([
          // Reset user data
          commit('KEYCLOAK_USER_LOGOUT'),
        ]);

        // Removing authorization from api requests
        resetAuthHeader();

        await state.keycloak.logout();
      },

      async resetUserData({ commit, dispatch }){
        Promise.allSettled([
          commit('KEYCLOAK_USER_LOGOUT'),
          dispatch('resetTicket'),
        ]);
        resetAuthHeader();
      },

      checkTokenIsExpired({ state, dispatch }) {
        state.keycloak.onTokenIsExpired = async () => {
          try {
            await state.keycloak.updateToken(100);
          } catch (error) {
            dispatch('resetUserData');
          }
        };
      },

      setUnauthorizedError({ commit }, showError) {
        commit('SET_UNAUTHORIZED_ERROR', showError);
      }
    },

    mutations: {
      KEYCLOAK_INIT(state, userInfo){
        state.keycloak = userInfo.keycloak;
      },

      KEYCLOAK_LOGIN(state, userInfo){
        if (userInfo) {
          setAuthHeader(userInfo.userToken);
          state.userInfo.name = userInfo.name;
          state.userInfo.email = userInfo.email;
          state.userInfo.cpf = userInfo.cpf;
          state.userInfo.phone = userInfo.phone;
          state.userInfo.birthdate = userInfo.birthdate;
          state.userInfo.userId = userInfo.userId;
          state.userInfo.imageUrl = userInfo.avatar;
          state.authenticated = userInfo.authenticated;
          state.userToken = userInfo.userToken;
          state.refreshToken = userInfo.refreshToken;
          state.keycloak = userInfo.keycloak;
        }
      },

      KEYCLOAK_USER_LOGOUT(state){
        state.userInfo.name = null;
        state.userInfo.email = null;
        state.userInfo.cpf = null;
        state.userInfo.gender = null;
        state.userInfo.birthdate = null;
        state.userInfo.phone = null;
        state.userInfo.userId = null;
        state.userInfo.avatar = null;
        state.authenticated = false;
        state.userToken = null;
      },

      KEYCLOAK_CHANGE_REQUIRED_LOGIN(state, loginRequired){
        state.keycloak.loginRequired = loginRequired;
      },

      KEYCLOAK_UPDATE_USER_TOKEN(state, payload){
        setAuthHeader(payload);
        state.userToken = payload;
      },

      CLIENT_ID(state, payload) {
        state.clientId = payload;
      },

      SET_UNAUTHORIZED_ERROR(state, payload) {
        state.unauthorizedError = payload;
      }
    }
};
