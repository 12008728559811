let id = 0;

export default {
  state: {
    notifications: []
  },

  getters: {
    getToasters: state => state.notifications
  },

  mutations: {
    PUSH(state, notification) {
      state.notifications.push({
        ...notification,
        id: id++
      });
    },
    DELETE(state, notificationToRemove) {
      state.notifications = state.notifications.filter(
        notification => notification.id !== notificationToRemove.id
      );
    }
  },
  actions: {
    addToaster({ commit }, notification) {
      commit('PUSH', notification);
    },
    removeToaster({ commit }, notificationToRemove) {
      commit('DELETE', notificationToRemove);
    }
  }
};