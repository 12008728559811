export default {
	state: {
		identificationType: 'identified',
	},

	getters: {
		identificationType: (state) => state.identificationType,
		isAnonymous: (state) => state.identificationType === 'anonymous',
		isConfidential: (state) => state.identificationType === 'confidential',
		isIdentified: (state) => state.identificationType === 'identified',
	},

	actions: {
		setIdentificationType({ commit }, type){
			commit('SET_IDENTIFICATION_TYPE', type);
		},
	},

	mutations: {
		SET_IDENTIFICATION_TYPE(state, payload){
			state.identificationType = payload;
		},
	},
};