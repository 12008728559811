import api from '@/api/survey';

export default {
  state: {
    survey: null,
  },

  getters: {
    survey: (state) => state.survey,
  },

  actions: {
    async setSurvey({ commit }, surveyID) {
      try {
        const res = await api.getSurvey(surveyID);

        commit('SET_SURVEY', res.data);

        return Promise.resolve(res);
      } catch (error) {
        commit('SET_SURVEY', null);

        return Promise.reject(error);
      }
    },
    async postSurvey({ commit, dispatch }, { data, surveyID } ) {
      try {
        const res = await api.postSurvey(data, surveyID);

        commit('SET_SURVEY', res.data);
        dispatch('resetSelectedSubject');

        return Promise.resolve(res);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  },

  mutations: {
    SET_SURVEY(state, survey) {
      state.survey = survey;
    }
  }
};