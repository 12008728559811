import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import store from '@/store/index';

Vue.use(Router);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
      // --- Old routes that need to be refact
    {
      path: '/',
      meta: {
        layout: 'Default',
      },
      component: () => import(/* webpackChunkName: "Default" */ '@/layouts/Default.vue'),

      children: [
        {
          path: '',
          name: 'Home',
          component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
        },
      ]
    },
    {
      path: '/categorias',
      meta: {
        layout: 'Footerless',
      },
      component: () => import(/* webpackChunkName: "Footerless" */ '@/layouts/Footerless.vue'),
      children: [
        {
          path: '',
          name: 'CategoryAll',
          component: () => import(/* webpackChunkName: "CategoryAll" */ '@/views/category/Index.vue'),

        },
        {
          path: ':categorySlug',
          name: 'CategorySingle',
          props: (route) => ({
            categorySlug: String(route.params.categorySlug),
          }),
          component: () => import(/* webpackChunkName: "CategorySingle" */ '@/views/category/Single.vue'),
        },
        {
          path: ':categorySlug/:subjectSlug',
          name: 'SubjectDetails',
          props: (route) => ({
            categorySlug: String(route.params.categorySlug),
            subjectSlug: String(route.params.subjectSlug),
          }),
          component: () => import(/* webpackChunkName: "Details" */ '@/views/category/Details.vue'),
        },
        {
          path: ':categorySlug/:subjectSlug/identificacao',
          name: 'SolicitationType',
          component: () => import(/* webpaclChunkName: "SolicitationType" */ '@/views/ticket/SolicitationType.vue'),
        },
        {
          path: ':categorySlug/:subjectSlug/identificacao/criar',
          name: 'SolicitationMake',
          component: () => import(/* webpackChunkName: "SolicitationMake" */ '@/views/ticket/SolicitationMake.vue'),
        },
        {
          path: ':categorySlug/:subjectSlug/identificacao/criar/confirmar',
          name: 'SolicitationFinish',
          component: () => import(/* webpackChunkName: "SolicitationFinish" */ '@/views/ticket/SolicitationFinish.vue')
        },
      ],
    },

    {
      path: '/manifestacao',
      meta: {
        layout: 'Footerless',
      },
      component: () => import(/* webpackChunkName: "Footerless" */ '@/layouts/Footerless.vue'),
      children: [
        {
          path: '',
          name: 'Manifestation',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/manifestation/ManifestationIndex.vue'),
        },
        {
          path: 'identificacao',
          name: 'ManifestationType',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/manifestation/ManifestationType.vue'),
        },
        {
          path: 'identificacao/criar',
          name: 'ManifestationMake',
          component: () => import(/* webpackChunkName: "Make" */ '@/views/manifestation/ManifestationMake.vue'),
        },
        {
          path: 'identificacao/criar/confirmar',
          name: 'ManifestationFinish',
          component: () => import(/* webpackChunkName: "FinishTicket" */ '@/views/manifestation/ManifestationFinish.vue')
        },
        {
          path: 'contato',
          name: 'ManifestationContact',
          component: () => import(/* webpackChunkName: "Contact" */ '@/views/manifestation/ManifestationContact.vue')
        }
      ]
    },

    {
      path: '/acesso-informacao',
      meta: {
        layout: 'Footerless',
      },
      component: () => import(/* webpackChunkName: "Footerless" */ '@/layouts/Footerless.vue'),
      children: [
        {
          path: '',
          name: 'Information',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/information/InfoIndex.vue'),
        },
        {
          path: 'contato',
          name: 'InfoContact',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/information/InfoContact.vue'),
        },
        {
          path: 'legislacao',
          name: 'InfoLegislation',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/information/InfoLegislation.vue'),
        },
        {
          path: 'relatorios',
          name: 'InfoReports',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/information/InfoReports.vue'),
        },
        {
          path: 'duvidas',
          name: 'InfoFAQ',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/information/InfoFAQ.vue'),
        },
        {
          path: 'orientacoes',
          name: 'InfoOrientation',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/information/InfoOrientation.vue'),
        },
        {
          path: 'prazoserecursos',
          name: 'InfoDeadlines',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/information/InfoDeadlines.vue'),
        },
        {
          path: 'selecionar',
          name: 'InfoSelection',
          component: () => import(/* webpackChunkName: "Manifestation" */ '@/views/information/InfoSelection.vue'),
        }
      ]
    },

    {
      path: '/confirmado',
      meta: {
        layout: 'Footerless',
      },
      component: () => import(/* webpackChunkName: "Footerless" */ '@/layouts/Footerless.vue'),
      children: [
        {
          path: '',
          name: 'TicketInfo',
          component: () => import(/* webpackChunkName: "TicketInfo" */ '@/views/ticket/TicketInfo.vue')
        }
      ],
    },

    {
      path: '/duvidas-frequentes',
      meta: {
        layout: 'Default',
      },
      component: () => import(/* webpackChunkName: "Default" */ '@/layouts/Default.vue'),
      children: [
        {
          path: '',
          name: 'Faq',
          component: () => import(/* webpackChunkName: "Faq" */ '@/views/FAQ/Index.vue'),
        },
        {
          path: ':faqSlug',
          name: 'FaqSingle',
          props: (route) => ({
            faqSlug: String(route.params.faqSlug),
          }),
          component: () => import(/* webpackChunkName: "FaqSingle" */ '@/views/FAQ/Single.vue'),
        },
      ]
    },

    {
      path: '/avalie',
      meta: {
        layout: 'Default',
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "Footerless" */ '@/layouts/Default.vue'),
      children: [
        {
          path: '',
          name: 'RatingHome',
          component: () => import(/* webpackChunkName: "RatingHome" */ '@/views/rating/Index.vue'),
        },
      ]
    },

    {
      path: '/avalie',
      meta: {
        layout: 'Footerless',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "Footerless" */ '@/layouts/Footerless.vue'),
      children: [
        {
          path: 'website',
          name: 'Rating',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "Rating" */ '@/views/rating/Generic.vue'),
        },
        {
          path: 'servico',
          name: 'ServiceRating',
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "ServiceRating" */ '@/views/rating/ServiceRating.vue')
        },
        {
          path: ':solicitationProtocol',
          name: 'RatingSingle',
          meta: {
            requiresAuth: false,
          },
          //auth is being treated in page
          props: (route) => ({ solicitationProtocol: String(route.params.solicitationProtocol) }),
          component: () => import(/* webpackChunkName: "RatingSingle" */ '@/views/rating/Single.vue'),
        },
        {
          path: 'whatsapp/:whatsappCode',
          name: 'RatingRomote',
          meta: { requiresAuth: false },
          props: (route) => ({ whatsappCode: String(route.params.whatsappCode) }),
          component: () => import(/* webpackChunkName: "RatingRemote" */ '@/views/rating/Remote.vue'),
        }
      ]
    },

    {
      path: '/nao-disponivel',
      name: 'NotAvaliable',
      component: () => import(/* webpackChunkName: "404" */ '@/views/rating/NotAvaliable.vue')
    },

    {
      path: '/solicitacoes',
      meta: {
        layout: 'Footerless',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "Default" */ '@/layouts/Footerless.vue'),
      children: [
        {
          path: '',
          name: 'Solicitations',
          meta: {
            requiresAuth: false,
          },
          component: () => import(/* webpackChunkName: "Solicitations" */ '@/views/solicitations/Index.vue'),
        },
        {
          path: 'consultar-solicitacoes',
          name: 'SolicitationsConsult',
          meta: {
            requiresAuth: false,
          },
          component: () => import(/* webpackChunkName: "Solicitations" */ '@/views/solicitations/SolicitationsConsult.vue'),
        },
        {
          path: ':solicitationProtocol',
          name: 'SolicitationSingle',
          props: (route) => ({
            solicitationProtocol: String(route.params.solicitationProtocol),
            accessCode: String(route.params.accessCode)
          }),
          component: () => import(/* webpackChunkName: "SolicitationSingle" */ '@/views/solicitations/Single.vue'),
        },
      ]
    },

    {
      path: '/dados',
      meta: {
        layout: 'Footerless',
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "Default" */ '@/layouts/Footerless.vue'),
      children: [
        {
          path: '',
          name: 'Docs',
          meta: {
            requiresAuth: false,
          },
          component: () => import(/* webpackChunkName: "Docs" */ '@/views/docs/Index.vue'),
        },
        {
          path: 'legislacao',
          name: 'DocLegislation',
          meta: {
            requiresAuth: false,
          },
          component: () => import(/* webpackChunkName: "Legislation" */ '@/views/docs/Legislation.vue'),
        },
        {
          path: 'relatorios',
          name: 'DocReports',
          meta: {
            requiresAuth: false,
          },
          component: () => import(/* webpackChunkName: "Reports" */ '@/views/information/InfoReports.vue'),
        },
      ]
    },

    {
      path: '*',
      name: 'Error',
      component: () => import(/* webpackChunkName: "404" */ '@/views/NotFound.vue')
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.userIsLogged) {
    store.dispatch('loginKeycloak', `${window.location.origin}`);
  }
  else {
    next();
  }
});

export default router;