export default {
  state: {
    information: {
      id: 5,
      name: 'Acesso à Informação',
      apiName: 'pedido_de_informacao',
      icon: 'InformationAccess',
      text: 'Solicite informações públicas ou documentos'
    },
    manifestation: null,
    isAnonymous: false,
    isConfidential: false,
  },

  getters: {
    getManifestation: (state) => state.manifestation,
    informationAccess: (state) => state.information
  },

  actions: {
    setManifestationType({ commit }, option) {
      commit('SET_MANIFESTATION_TYPE', option);
    },

    clearManifestationType({ commit }) {
      commit('CLEAR_MANIFESTATION_TYPE');
    }
  },

  mutations: {
    SET_MANIFESTATION_TYPE(state, option) {
      state.manifestation = option;
    },

    CLEAR_MANIFESTATION_TYPE(state) {
      state.manifestation = null;
    }
  }
};